import React from 'react';
import Header from '../../../../component/header'
import {Button, Grid, Notify, Tag,Tabs,Sweetalert,Input} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/merchant/index.css'
const TabPanel = Tabs.TabPanel;
export default class MainUserUserIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100003,100009);
        this.dataBind(this.state.gridPageCurrent);
    }
    dataBind(pageindex){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"user",
            action:"list",
            data:{
                pageindex,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current);
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'',
                width:'90px',
                bodyRender:(data)=>{
                    return <div className="grid-avatar">
                        <div className="grid-avatar-hd">
                            <img src={data.avatar}/>
                        </div>
                        <div className="grid-avatar-bd">
                            {data.id}
                        </div>
                    </div>
                }
            },
            {
                title:'账户信息',
                name:'title',
                bodyRender:(data)=>{
                    const shimingTag=()=>{
                        if(data.shiming==1)
                        {
                            return <Tag theme="blue">已实名</Tag>
                        }
                        else
                        {
                            return <Tag theme="yellow">未实名</Tag>
                        }
                    }
                    return <div className="font12">
                        <div>{data.nickname}</div>
                        <div>
                            {data.tel}
                        </div>
                        <div>{shimingTag()}</div>
                    </div>
                }
            },
            {
               title: '注册时间',
                name:'addtime',
                width:'150px',
            }
        ];
        return listColumns;
    }
    render() {

        return(
            <div className="work-page">
                <div className="work-header">
                    <Header data={[{name:'用户'},{name:'求职用户'}]}/>
                </div>
                <div className="work-body">
                    <div className="work-body-hd"></div>
                    <div className="work-body-bd">
                        <div className="work-body-bd-hd">
                        </div>
                        <Grid
                             columns={this.getGridColumns()}
                             datasets={this.state.dataList}
                             pageInfo={{
                                  current: this.state.gridPageCurrent,
                                  pageSize: this.state.gridPageSize,
                                  total: this.state.gridPageTotal,
                                  pageSizeOptions: [this.state.gridPageSize],
                             }}
                             loading={this.state.gridLoading}
                             onChange={this.onGridChange.bind(this)}
                             ellipsis
                             rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
