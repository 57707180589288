import React from 'react';
import Header from '../../../../component/header'
import {Button, Grid, Notify, Tag,Tabs,previewImage,Input} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/merchant/index.css'
const TabPanel = Tabs.TabPanel;
export default class MainAgentUserIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            dataIndex:-1,
            gridNoteValue:'',
            successBtnLoading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100004,100012);
        this.dataBind(this.state.gridPageCurrent);
    }
    dataBind(pageindex){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"agent",
            action:"list",
            data:{
                pageindex,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current);
    }
    GridItemQrCodeClick(url){
        previewImage({
          images: [url],
          index: 0,
          parentComponent: this,
          scaleRatio: 3
        });
    }
    GridItemEditClick(index){
        this.setState({
            dataIndex:index
        })
    }
    GridItemSuccessClick=()=>{
        this.setState({
            successBtnLoading:true
        })
        const index=this.state.dataIndex;
        const id=this.state.dataList[index].id;
        const text=this.state.gridNoteValue;
        const _this=this;
        Service.Post({
            name:"agent",
            action:"setnote",
            data:{
                id,
                text
            },
            success(result)
            {
                if(result.retcode==200){
                    let list=_this.state.dataList;
                    list[index].note=text;
                    _this.setState({
                        successBtnLoading:false,
                        dataIndex:-1,
                        dataList:list
                    })
                }
                else
                {
                    _this.setState({
                        successBtnLoading:false,
                    })
                     Notify.error(result.retmsg);
                }
            },
            fail(){
                     _this.setState({
                        successBtnLoading:false,
                    })
                Notify.error("网络连接失败");
            }
        })
    }
    GridItemInput=(e)=>{
        this.setState({
            gridNoteValue:e.target.value
        })
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'编号',
                width:'90px',
                name:"code"
            },
            {
              title:'备注信息',
                width:'300px',
                bodyRender:(data,pro)=>{
                    if(this.state.dataIndex==pro.row)
                    {
                        return <Input maxLength="50" value={this.state.gridNoteValue} onInput={this.GridItemInput}/>
                    }
                    else
                    {
                        return <div>{data.note}</div>
                    }
                }
            },
            {
                title:'推广二维码',
                bodyRender:(data)=>{
                    return <div className="grid-avatar">
                        <Button  onClick={this.GridItemQrCodeClick.bind(this,data.tgqrcode)}>点击查看</Button>
                    </div>
                }
            },
             {
                title:'数据二维码',
                bodyRender:(data)=>{
                    return <div className="grid-avatar">
                        <Button onClick={this.GridItemQrCodeClick.bind(this,data.dataqrcode)}>点击查看</Button>
                    </div>
                }
            },
            {
               title: '今日推广',
                name:'jrcount',
            },
            {
               title: '昨日推广',
                name:'zrcount',
            },
            {
               title: '本月推广',
                name:'bycount',
            },
            {
               title: '总推广',
                name:'allcount',
            },
            {
               title: '操作',
                bodyRender:(data,pro)=>{
                    if(this.state.dataIndex==pro.row)
                    {
                        return <div>
                            <Button type="primary" loading={this.state.successBtnLoading} onClick={this.GridItemSuccessClick}>保存</Button>
                            <Button type="default" onClick={this.GridItemEditClick.bind(this,-1)}>取消</Button>
                        </div>
                    }
                    else
                    {
                        return <Button type="warning" onClick={this.GridItemEditClick.bind(this,pro.row)}>编辑</Button>
                    }
                }
            }
        ];
        return listColumns;
    }
    render() {

        return(
            <div className="work-page">
                <div className="work-header">
                    <Header data={[{name:'代理'},{name:'代理管理'}]}/>
                </div>
                <div className="work-body">
                    <div className="work-body-hd"></div>
                    <div className="work-body-bd">
                        <div className="work-body-bd-hd">
                        </div>
                        <Grid
                             columns={this.getGridColumns()}
                             datasets={this.state.dataList}
                             pageInfo={{
                                  current: this.state.gridPageCurrent,
                                  pageSize: this.state.gridPageSize,
                                  total: this.state.gridPageTotal,
                                  pageSizeOptions: [this.state.gridPageSize],
                             }}
                             loading={this.state.gridLoading}
                             onChange={this.onGridChange.bind(this)}
                             ellipsis
                             rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
