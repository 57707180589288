import React from 'react';
import Header from '../../../component/header'
import {Button, Grid, Notify, Tag,Tabs,Badge} from 'zent'
import Service from "../../../config/Service";
import '../../../style/css/main/customer/index.css'
import Config from "../../../config/Config";

let maincustomer_lunxunId='';
export default class MainCustomerIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100005,100013);
        this.dataBind(this.state.gridPageCurrent);
        this.lunXunData();
    }
    componentWillUnmount() {
        clearInterval(maincustomer_lunxunId);
    }

    dataBind(pageindex){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"customer",
            action:"list",
            data:{
                pageindex,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    lunXunData(){
        const _this=this;
        maincustomer_lunxunId=setInterval(()=>{
            _this.dataBind(_this.state.gridPageCurrent);
        },10000)
    }
    onGridItemClick=(e)=>{
        window.open("/customer/0/"+e);
        const _this=this;
        setTimeout(()=>{
            _this.dataBind(_this.state.gridPageCurrent);
        },2000)
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current);
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'头像',
                width:'90px',
                textAlign:'center',
                bodyRender:(data)=>{
                    return <div className="grid-avatar">
                        <div className="grid-avatar-hd">
                            <Badge count={data.count} maxCount={99}>
                            <img src={data.avatar}/>
                            </Badge>
                        </div>
                    </div>
                }
            },
            {
                title:'用户信息',
                width:'150px',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <div>{data.nickname}</div>
                        <div>
                            {data.tel}
                        </div>
                    </div>
                }
            },
            {
                title: '最新消息',
                name:'message'
            },
            {
               title: '当前未读消息',
                name:'count',
                width:'150px',
                textAlign:'center',
            },
            {
               title: '最后信息时间',
                name:'time',
                width:'150px',
            },
            {
               title: '操作',
                width:'150px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <div>
                            <Button type="primary" onClick={this.onGridItemClick.bind(this,data.id)}>进入聊天</Button>
                        </div>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {

        return(
            <div className="work-page">
                <div className="work-header">
                     <Header data={[{name:'客服'},{name:'客服系统'}]}/>
                </div>
                <div className="work-body">
                    <div className="work-body-hd"></div>
                    <div className="work-body-bd">
                        <div className="work-body-bd-hd">

                        </div>
                        <Grid
                             columns={this.getGridColumns()}
                             datasets={this.state.dataList}
                             pageInfo={{
                                  current: this.state.gridPageCurrent,
                                  pageSize: this.state.gridPageSize,
                                  total: this.state.gridPageTotal,
                                  pageSizeOptions: [this.state.gridPageSize],
                             }}
                             loading={this.state.gridLoading}
                             onChange={this.onGridChange.bind(this)}
                             ellipsis
                             rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
