import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,FormSelectField,Validators,BlockLoading} from 'zent';
import Service from "../../../../config/Service";
export default class MainSystemUserEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            dataList:[],
            adminId:0,
            editData:{},
            roleList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100001,100006);
        const id=this.props.match.params.id||0;
        this.setState({
            adminId:id
        })
        this.roleBind();
        this.dataBind(id);
    }
    roleBind(){
        const _this=this;
        Service.Post({
            name:"AdminRole",
            action:"suclist",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        roleList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminuser",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getValue();
            // onSubmit可以返回一个Promise，onSubmitSuccess会在其resolve之后调用
            return new Promise(resolve => {
               Service.Post({
                   name:"AdminUser",
                   action:"edit",
                   data:{
                       id:_this.state.adminId,
                       name:value.name,
                       tel:value.tel,
                       username:value.username,
                       userpwd:value.userpwd,
                       roleid:value.role.key,
                       status:1
                   },
                   success(result){
                       if(result.retcode==200)
                       {
                           Notify.success("操作成功",1500,()=>{
                               setLoading(false);
                               _this.props.history.goBack();
                           })
                       }
                       else
                       {
                           Notify.warn(result.retmsg);
                           setLoading(false);
                       }
                   },
                   fail(){
                       Notify.error("网络连接失败");
                       setLoading(false);
                   }
               })
            });
      }, []);
      const onSubmitSuccess = React.useCallback((e) => {
          Notify.success("提交成功",1500,()=>{
              _this.props.history.goBack();
          })
      }, []);
      const onSubmitFail = React.useCallback((error) => {

      }, []);
      const pwdtype=this.state.adminId==0?0:1;
      const ButtonView=()=>{
            if(stateLoading){
                return false;
            }
            return(
                <div className="form-submit app-fixed-container">
                    <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                    <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                </div>
            )
        }
      return(
           <Form
              disabled={loading}
              form={form}
              layout="horizontal"
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              onSubmitFail={onSubmitFail}
            >
               <FormInputField
                   name="name"
                   label="姓名："
                   required
                   props={{
                       maxLength:10,
                       placeholder:"请输入真实姓名",
                   }}
                   initialValue={editData.name}
                   validators={[
                       Validators.pattern(/^[\u4E00-\u9FA5]+$/, '必须为汉字')
                   ]}
               />
               <FormInputField
                   name="tel"
                   label="电话："
                   required
                   props={{
                       maxLength:11,
                       placeholder:"请输入手机号码"
                   }}
                   initialValue={editData.tel}
                   validators={[
                       Validators.pattern(/^(1[1-9])\d{9}$/, '手机号码错误')
                   ]}
               />
               <FormInputField
                   name="username"
                   label="账号："
                   required
                   props={{
                       maxLength:16,
                       placeholder:"请输入账号"
                   }}
                   initialValue={editData.username}
                   validators={[
                       Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).{6,16}$/, '至少包含字母、数字，6-16位')
                   ]}
               />
               <FormInputField
                   name="userpwd"
                   label="密码："
                   required={pwdtype==1?false:true}
                   validators={[
                       Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '至少包含字母、数字，6-16位')
                   ]}
                   helpDesc={pwdtype==1?"为空不修改":""}
                   props={{
                       maxLength:16,
                       placeholder:"请输入密码"
                   }}
               />
               <FormSelectField
                name="role"
                label="权限："
                required
                initialValue={editData.role}
                props={{
                  options: _this.state.roleList,
                    width:'195px'
                }}
              />
               <ButtonView/>
        </Form>
      )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'员工管理',href:'/main/system/user'},{name:'编辑员工'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}