import React from 'react';
import Header from '../../../../component/header'
import {Drawer, Grid, Notify, Tag,Tabs} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/work/index.css'
import {Link} from 'react-router-dom'
import {WorkNews,WorkUserList} from './news'
const TabPanel = Tabs.TabPanel;
export default class MainWorkWorkIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            activeId:"20",
            gridPageCurrent:0,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            shenheErrContentValue:'',
            dataList:[],
            newsDrawerViewVisible:false,
            listDrawerViewVisible:false,
            workCurrentIndex:-1
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100002,100008);
        this.dataBind(this.state.activeId);
    }
    dataBind(status){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageCurrent,gridPageSize}=this.state;
        Service.Post({
            name:"work",
            action:"list",
            data:{
                status,
                pageindex:gridPageCurrent,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    onTabsChange=(e)=>{
        this.setState({
            activeId:e
        })
        this.dataBind(e);
    }
    onGridChange=(e)=>{

    }
    onDelDataList(index){
        let list=JSON.parse(JSON.stringify(this.state.dataList));
        list.splice(index,1);
        this.setState({
            dataList:list,
            newsDrawerViewVisible:false,
            workCurrentIndex:-1
        })
    }
    onOpenListView=(index)=>{
        if(index<0)
        {
            return false;
        }
        this.setState({
            listDrawerViewVisible:true,
            workCurrentIndex:index
        })
    }
    onOpenNewsView=(index)=>{
        if(index<0)
        {
            return false;
        }
        this.setState({
            newsDrawerViewVisible:true,
            workCurrentIndex:index
        })
    }
    onCloseNewsView=()=>{
        this.setState({
            newsDrawerViewVisible:false,
            workCurrentIndex:-1
        })
    }
    onCloseListView=()=>{
        this.setState({
            listDrawerViewVisible:false,
            workCurrentIndex:-1
        })
    }
    getTabsPanels=()=>{
        const tabsPanels = [
            <TabPanel key="0" tab="待审核" id="10">
            </TabPanel>,
            <TabPanel key="1" tab="已审核通过" id="20">
            </TabPanel>,
            <TabPanel key="2" tab="审核未通过" id="30">
            </TabPanel>,
            <TabPanel key="3" tab="已结束" id="200">
            </TabPanel>,
        ];
        return tabsPanels;
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'岗位标题',
                name:'title',
                bodyRender:(data,pro)=>{
                    return <div>
                        <div style={{fontWeight:'bold'}}>
                            <a onClick={this.onOpenNewsView.bind(this,pro.row)}>{data.title}</a>
                        </div>
                        <div>
                            <Tag style={{marginRight:'5px'}} theme="grey">{data.sex}</Tag>
                            <Tag style={{marginRight:'5px'}} theme="grey">{data.age}</Tag>
                            <Tag style={{marginRight:'5px'}} theme="grey">{data.height}</Tag>
                            <Tag style={{marginRight:'5px'}} theme="grey">{data.record}</Tag>
                        </div>

                    </div>
                }
            },
            {
                title:'薪资',
                textAlign: 'center',
                width:'130px',
                bodyRender:(data)=>{
                    return <div>
                        {data.money}/{data.moneyunit}/{data.moneysettlement}
                    </div>
                }
            },
            {
                title: '人数',
                textAlign: 'center',
                width:'100px',
                bodyRender:(data,pro)=>{
                    return <a onClick={this.onOpenListView.bind(this,pro.row)}>
                        {data.bmnumber}/{data.number}
                    </a>
                }
            },
            {
                title:'地区',
                width:'250px',
                bodyRender:(data)=>{
                    return <div>
                        <div>{data.city}-{data.area}</div>
                    </div>
                }
            },
            {
                title:'发布者',
                name:'user',
                width:'150px',
                bodyRender:(data)=>{
                    return <div>
                        {data.username}
                    </div>
                }
            },
            {
                title:'所属企业',
                width:'150px',
                bodyRender:(data)=>{
                    return <div>
                        {data.company}
                    </div>
                }
            },
        ];
        return listColumns;
    }
    render() {
        const NewsView=()=> {
            const visible = this.state.newsDrawerViewVisible;
            const index=this.state.workCurrentIndex;
            if(index>=0)
            {
                const id=this.state.dataList[index].id||0;
                return(
                    <Drawer
                        title="岗位详情"
                        size={"small"}
                        visible={visible}
                        onClose={this.onCloseNewsView}>
                        <WorkNews id={id} index={index} parent={this}/>
                    </Drawer>
                )
            }
            return<></>
        }
        const UserListView=()=>{
            const visible = this.state.listDrawerViewVisible;
            const index=this.state.workCurrentIndex;
            if(index>=0)
            {
                const id=this.state.dataList[index].id||0;
                return(
                    <Drawer
                        title="报名列表"
                        onClose={this.onCloseListView}
                        visible={visible}>
                        <WorkUserList id={id}/>
                    </Drawer>
                )}
            return<></>
        }
        return(
            <div>
                <Header data={[{name:'职位'},{name:'职位管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">

                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Tabs
                            activeId={this.state.activeId}
                            onChange={this.onTabsChange}
                            type="card"
                        >
                            {this.getTabsPanels()}
                        </Tabs>
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: 20,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [20],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            rowKey="id"/>
                        <NewsView/>
                        <UserListView/>
                    </div>
                </div>
            </div>
        )
    }
}
