import {Link} from "react-router-dom";

function Header(e) {
    const data=e.data||[];
    const LinksView=data.map((item,index)=>{
        if(index==data.length-1)
        {
            if(item.href)
            {
                return <div className="page-right-header-cell" key={'headernavlink'+index}>
                    <div className="page-right-header-cell-hd"><Link to={item.href}>{item.name}</Link></div>
                </div>
            }
            else
            {
                return <div className="page-right-header-cell" key={'headernavlink'+index}>
                    <div className="page-right-header-cell-hd">{item.name}</div>
                </div>
            }
        }
        else
        {
            if(item.href)
            {
                return <div className="page-right-header-cell" key={'headernavlink'+index}>
                    <div className="page-right-header-cell-hd"><Link to={item.href}>{item.name}</Link></div>
                    <div className="page-right-header-cell-bd">/</div>
                </div>
            }
            else
            {
                return <div className="page-right-header-cell" key={'headernavlink'+index}>
                    <div className="page-right-header-cell-hd">{item.name}</div>
                    <div className="page-right-header-cell-bd">/</div>
                </div>
            }
        }
    })
    return (
        <div className="page-right-header">
            <div className="page-right-header-cells">
                    {LinksView}
                </div>
        </div>
    );
}
export default Header;
