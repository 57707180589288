import React from 'react';
import '../../style/css/main/index.css'
export default class Index extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }
    componentWillMount() {

    }

    render() {
        return (
            <div className="index-page">
               兼途
            </div>

        )
    }

}
