import React from 'react';
import Left from "../component/left";
import {Route, Switch} from "react-router-dom";
import Parameter from '../config/Config';
import '../style/css/main.css'
import Service from "../config/Service";
import MainIndex from '../pages/main/index'
import MainUserUserIndex from "../pages/main/user/user";
import MainWorkWorkIndex from "../pages/main/work/work";
import MainUserMerchantIndex from "../pages/main/user/merchant";
import MainUserCompanyIndex from "../pages/main/user/company";
import MainCustomerIndex from "../pages/main/customer";
import MainSystemUserIndex from "../pages/main/system/user";
import MainSystemUserEdit from "../pages/main/system/user/edit";
import MainSystemRoleIndex from "../pages/main/system/role";
import MainSystemRoleEdit from "../pages/main/system/role/edit";
import MainAgentUserIndex from "../pages/main/agent/user";
export default class Main extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            successCode:false,
            menuList:[],
            navId:0,
            menuId:0,
            roleCode:2
        }
    }
    onMenuChange=(navid,menuid)=>
    {
        this.onYzRole(menuid);
        this.setState({
            navId:navid,
            menuId:menuid
        })
    }
    onYzRole(rolecode){
        const _this=this;
        Service.Post({
            name:"adminrole",
            action:"role",
            data:{rolecode},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        roleCode:2
                    })
                }
                else
                {
                    _this.setState({
                        roleCode:1
                    })
                }
            },
            fail(er){
                _this.setState({
                        roleCode:1
                    })
            }
        })
    }
    componentWillMount() {
        this.menuBind();
    }
    menuBind(){
        const _this=this;
        Service.Post({
            name:"menu",
            action:"menuList",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        menuList:result.list
                    })
                }
            }
        })
    }
    yanzhengBind=()=>{
        const usersession=JSON.parse(sessionStorage.getItem(Parameter.usersession));
        if(!usersession)
        {
            sessionStorage.removeItem(Parameter.usersession);
            window.location.replace("/login")
            return false;

        }
        else
        {
            if(!usersession.id||!usersession.token||!usersession.role)
            {
                sessionStorage.removeItem(Parameter.usersession)
                window.location.replace("/login")
                return false;
            }
        }
        return true;
    }
    render() {
        if(this.yanzhengBind())
        {
            if(this.state.roleCode==2)
            {
                return(<div className="page-main">
                    <Left menu={this.state.menuList} navid={this.state.navId} menuid={this.state.menuId}/>
                    <div className="page-right">
                        <Switch>
                            <Route exact render={(props)=>(
                                <MainIndex {...props} parent={this}/>
                            )} path={"/main"}/>
                            <Route exact render={(props)=>(
                                <MainUserUserIndex {...props} parent={this}/>
                            )} path={"/main/user/user"}/>
                            <Route exact render={(props)=>(
                                <MainWorkWorkIndex {...props} parent={this}/>
                            )} path={"/main/work/work"}/>
                            <Route exact render={(props)=>(
                                <MainUserMerchantIndex {...props} parent={this}/>
                            )} path={"/main/user/merchant"}/>
                            <Route exact render={(props)=>(
                                <MainUserCompanyIndex {...props} parent={this}/>
                            )} path={"/main/user/company"}/>
                            <Route exact render={(props)=>(
                                <MainSystemUserIndex {...props} parent={this}/>
                            )} path={"/main/system/user"}/>
                            <Route exact render={(props)=>(
                                <MainSystemUserEdit {...props} parent={this}/>
                            )} path={"/main/system/user/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemRoleIndex {...props} parent={this}/>
                            )} path={"/main/system/role"}/>
                            <Route exact render={(props)=>(
                                <MainSystemRoleEdit {...props} parent={this}/>
                            )} path={"/main/system/role/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainAgentUserIndex {...props} parent={this}/>
                            )} path={"/main/agent/user"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerIndex {...props} parent={this}/>
                            )} path={"/main/customer"}/>
                        </Switch>
                    </div>
                </div>)
            }
        }
        return <div>无权限访问</div>;
    }
}
