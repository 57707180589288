import React from 'react';
import { Button,Input,Notify } from 'zent';
import '../style/css/customer.css'
import Service from "../config/Service";
import Config from "../config/Config";
import Function from "../config/Function";
let customer_toId=0;
let customer_userId=0;
let customer_socket=null;
export default class Customer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataList:[],
            userData:{},
            messageValue:""
        }
    }
    componentWillMount() {
          const id=this.props.match.params.id;
          const toid=this.props.match.params.toid;
          customer_toId=toid;
          customer_userId=id;
          this.dataBind(id,toid);
          this.socketBind();
    }
    componentWillUnmount(){
        customer_socket.onclose();
    }
    socketBind(){
       customer_socket=new WebSocket(Config.socketUrl+'?user='+customer_userId);
       customer_socket.onopen=this.GetSocketOpen.bind(this);
       customer_socket.onmessage=this.GetSocketMessage.bind(this);
    }
    GetSocketOpen(e){
        console.log("打开了",e);
    }
    GetSocketMessage(mess){
        const data=JSON.parse(mess.data);
        this.SetDataList(data);
    }
    dataBind(id,toid){
        const _this=this;
        Service.Post({
            name:"Customer",
            action:"ltdatalist",
            data:{
                id,
                toid
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        userData:result.user
                    })
                }
            }
        })
    }
    onMessageChange=(e)=>{
       this.setState({messageValue:e.target.value})
    }
    onSendMessageClick=()=>{
        const {messageValue,userData}=this.state;
        const touser=customer_toId;
        const avatar=userData.avatar||'';
        const name=userData.name||'';
        const userid=customer_userId;
        const message=messageValue;
        const time=Function.DateFormat(new Date(),"yyyy-MM-dd hh:mm:ss");
        const sendData={
            userid,
            message,
            time,
            avatar,
            name
        }
        const sendmessage=touser+"|"+JSON.stringify(sendData);
        this.setState({
            messageValue:""
        })
        this.SetDataList(sendData);
        this.sendDataBind(userid,touser,message,time);
        customer_socket.send(sendmessage);

    }
    sendDataBind(fromid,toid,message,time){
        Service.Post({
            name:"Customer",
            action:"create",
            data:{
               fromid,
                toid,
                message,
                time
            },
            success(result)
            {

            }
        });
    }
    SetDataList=(data)=>{
        let dataList=JSON.parse(JSON.stringify(this.state.dataList));
        dataList.push(data);
        this.setState({
            dataList:dataList,
        })
        setTimeout(()=>{
            document.body.scrollTop = document.documentElement.scrollTop = 10000000;
        },30)
    }
    GetListView=()=>{
        const dataList=this.state.dataList;
        let views=dataList.map((item,index)=>{
            let xzclass="customer-body-cell";
            if(item.userid==customer_userId)
            {
                xzclass="customer-body-cell customer-xuanzhuan"
            }
            return <div className={xzclass} key={'list'+index}>
                        <div className="customer-body-cell-box">
                            <div className="customer-body-cell-hd">
                                <img src={item.avatar}/>
                            </div>
                            <div className="customer-body-cell-bd">
                                <div className="customer-body-cell-bd-hd">{item.name}</div>
                                <div className="customer-body-cell-bd-bd">
                                    <div className="customer-ltcontent">
                                         <div className="customer-ltcontent-jt">

                                            </div>
                                            <div className="customer-ltcontent-box">
                                                {item.message}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customer-body-cell-bd-ft">
                            <span>{item.time}</span>
                        </div>
                    </div>
        })
        return views;
    }
    render() {
        return (
            <div className="customer-page">
                <div className="customer-body">
                    <div className="customer-body-cells">
                        {this.GetListView()}
                    </div>
                </div>
                <div className="customer-footer">
                    <div className="customer-footer-hd">
                        <Input type="text" maxLength="500" value={this.state.messageValue} onChange={this.onMessageChange} placeholder="请输入内容"></Input>
                    </div>
                    <div className="customer-footer-bd">
                        <Button type="success" onClick={this.onSendMessageClick}>发送</Button>
                    </div>
                </div>
            </div>
        )
    }

}
