import React from 'react';
import {BlockLoading, Button, Tag, Sweetalert, Notify, Input, Tabs, Icon,Drawer,Timeline} from "zent";
import "../../../../style/css/main/work/news.css"
import Service from "../../../../config/Service";
const TabPanel = Tabs.TabPanel;
export function WorkNews(e) {
    let errValue='';
    const _this=e.parent;
    const id=e.id;
    const index=e.index;
    const [loading,setLoading]=React.useState(true);
    const [btndisable,setBtnDisable]=React.useState(false);
    const [data,setData]=React.useState({});
    const [state,setState]=React.useState(0);
    const onErrorShenheInputChange=(e)=>{
        errValue=e.target.value;
    }
    const onSuccessShenHeClick=()=>{
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                setBtnDisable(true);
                onSubmitBind({
                    status:20,
                    content:'',
                    resolve:resolve,
                    reject:reject
                })
            })
        });
    }
    const onErrorShenHeClick=()=>{
        Sweetalert.confirm({
            content:<div><Input maxLength="140"  onChange={onErrorShenheInputChange} placeholder="请输入拒绝理由" /></div>,
            title: '请输入拒绝理由，提交成功后无法恢复，请确认',
            onConfirm: () => new Promise((resolve,reject) => {
                if(errValue.length<=0)
                {
                    Notify.warn("请输入拒绝理由");
                    reject();
                }
                else
                {
                    onSubmitBind({
                        status:30,
                        content:errValue,
                        resolve:resolve,
                        reject:reject
                    })
                }
            }),
        });
    }
    const onShelvesClick=()=>{
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                onSubmitBind({
                    status:200,
                    content:'',
                    resolve:resolve,
                    reject:reject
                })
            })
        });
    }
    const onSubmitBind=(req)=>{
        setBtnDisable(true);
        Service.Post({
            name: "work",
            action: "shenhe",
            data: {
                id,
                status: req.status,
                content: req.content
            },
            success(result) {
                if (result.retcode == 200) {
                    Notify.success("操作成功");
                    _this.onDelDataList(index);
                    req.resolve();
                } else {
                    Notify.warn(result.retmsg);
                    req.reject();
                }
            },
            fail() {
                Notify.error("网络连接失败");
                req.reject();
            },
            complete() {
                setBtnDisable(false);
            }
        })
    }
    if(id&&id>0&&!data.title)
    {
        Service.Post({
            name:"work",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    setData(result.data);
                    setState(2);
                }
                else
                {
                    setState(1);
                }
            },
            fail(){
                setState(1);
            },
            complete(){
                setLoading(false);
            }
        })
    }
    let viewHtml=<div className="worknews-note">加载中</div>;
    if(state==1)
    {
        viewHtml=<div className="worknews-note">加载失败</div>
    }
    else if(state==2)
    {
        let labelsView;

        if(data.label&&data.label.length>0)
        {
            labelsView=data.label.map((item,index)=>{
                return <Tag key={"fltag"+index} className={"tagcell"}>{item}</Tag>
            })
        }
        let stateView;
        let btnView;
        if(data.state==10)
        {
            stateView= <div className="worknews-drawer-bd-hd">
                <div className="worknews-drawer-bd-state">
                    <div className="worknews-drawer-bd-state-title st">等待审核</div>
                </div></div>
            btnView=<div className="worknews-drawer-bd-cells">
                <div className="worknews-drawer-bd-cell">
                    <Button type="primary" disabled={btndisable} onClick={onSuccessShenHeClick}>审核通过</Button>
                </div>
                <div className="worknews-drawer-bd-cell">
                    <Button type="warning" disabled={btndisable} onClick={onErrorShenHeClick}>审核拒绝</Button>
                </div>
            </div>

        }
        else if(data.state==20)
        {
            stateView= <div className="worknews-drawer-bd-hd">
                <div className="worknews-drawer-bd-state">
                    <div className="worknews-drawer-bd-state-title su">审核通过</div>
                </div></div>
            btnView=<div className="worknews-drawer-bd-cells">
                <div className="worknews-drawer-bd-cell">
                    <Button type="error"  disabled={btndisable} onClick={onShelvesClick}>强制下架</Button>
                </div>
            </div>
        }
        else if(data.state==30)
        {
            stateView= <div className="worknews-drawer-bd-hd">
                <div className="worknews-drawer-bd-state">
                    <div className="worknews-drawer-bd-state-title er">审核拒绝</div>
                    <div className="worknews-drawer-bd-state-content">
                        {data.statecontent}
                    </div>
                </div></div>
        }
        viewHtml=<div className="worknews-drawer">
            <div className="worknews-drawer-hd">
                <div className="worknews-page">
                    <div className="worknews-header">
                        <div className="worknews-header-hd">
                            {data.title}
                        </div>
                        <div className="worknews-header-bd">
                            <div className="worknews-header-bd-hd">{data.money}元/{data.moneyunit}</div>
                            <div className="worknews-header-bd-bd">
                                <span>{data.area}</span>
                                <span>{data.class}</span>
                                <span>{data.moneysettlement}</span>
                            </div>
                        </div>
                        <div className="worknews-header-ft">
                            <Tag  className={"tagcell"}>招聘{data.number}人</Tag>
                            <Tag  className={"tagcell"}>{data.sex}</Tag>
                            <Tag  className={"tagcell"}>年龄{data.age}</Tag>
                            <Tag  className={"tagcell"}>身高{data.height}</Tag>
                            <Tag  className={"tagcell"}>{data.record}</Tag>
                        </div>
                    </div>
                    <div className="worknews-body">
                        <div className="worknews-body-cells">
                            <div className="worknews-body-cell">
                                <div className="worknews-body-cell-hd">
                                    工作日期
                                </div>
                                <div className="worknews-body-cell-bd">
                                    {data.workdate}
                                </div>
                            </div>
                            <div className="worknews-body-cell">
                                <div className="worknews-body-cell-hd">
                                    工作时段
                                </div>
                                <div className="worknews-body-cell-bd">
                                    {data.worktime}
                                </div>
                            </div>
                            <div className="worknews-body-cell">
                                <div className="worknews-body-cell-hd">
                                    工作地址
                                </div>
                                <div className="worknews-body-cell-ft">
                                    {data.addressstreet}
                                </div>
                                <div className="worknews-body-cell-bd">
                                    {data.address}
                                </div>
                            </div>
                            <div className="worknews-body-cell">
                                <div className="worknews-body-cell-hd">
                                    联系方式
                                </div>
                                <div className="worknews-body-cell-bd">
                                    {data.contactname} {data.contacttel}
                                </div>
                            </div>
                            <div className="worknews-body-cell">
                                <div className="worknews-body-cell-hd">
                                    工作福利
                                </div>
                                <div className="worknews-body-cell-bd">
                                    {labelsView}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="worknews-footer">
                        <div className="worknews-footer-title">
                            岗位详情
                        </div>
                        <div className="worknews-footer-content">
                            {data.content}
                        </div>
                    </div>
                </div>
            </div>
            <div className="worknews-drawer-bd">
                {stateView}
                {btnView}
            </div>
        </div>
    }
    return(
        <BlockLoading loading={loading} height="100%">
            {viewHtml}
        </BlockLoading>
    )
}
export class WorkUserList extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            activeId:'0',
            loading:false,
            lqList:[],
            jgList:[],
            showLogsDarwer:false,
            darwerWork:0,
            darwerUser:0
        }
    }
    componentWillMount() {
        const id = this.props.id || 0;
        this.dataBind(id);
    }
    dataBind(id){
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"work",
            action:"userlist",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        loading:false,
                        lqList:result.data.lq,
                        jgList:result.data.jg
                    })
                }
                else
                {
                    _this.setState({
                        loading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    loading:false
                })
            }
        })
    }
    onCellClick=(work,user)=>{
        this.setState({
            showLogsDarwer:true,
            darwerWork:work,
            darwerUser:user
        })
    }
    onTabsChange=(e)=>{
        this.setState({
            activeId:e
        })
    }
    lqlistView=()=>{
        const view=this.state.lqList.map((item,index)=>{
            return(
                <div className="userlist-lq-cell" key={'userlistlqcell'+index} onClick={this.onCellClick.bind(this,item.work,item.user)}>
                    <div className="userlist-lq-cell-hd">
                        <img src={item.avatar}/>
                    </div>
                    <div className="userlist-lq-cell-bd">
                        <div className="userlist-lq-cell-bd-hd">
                            {item.name}
                        </div>
                        <div className="userlist-lq-cell-bd-bd">
                            {item.tel}
                        </div>
                    </div>
                    <div className="userlist-lq-cell-ft">
                        <Icon type="right" />
                    </div>
                </div>
            )
        })
        if(view.length<=0)
        {
            return <div className="userlist-lq-content">暂无用户</div>
        }
        return view;
    }
    jglistView=()=>{
        const view=this.state.jgList.map((item,index)=>{
            return(
                <div className="userlist-lq-cell" key={'userlistjgcell'+index} onClick={this.onCellClick.bind(this,item.work,item.user)}>
                    <div className="userlist-lq-cell-hd">
                        <img src={item.avatar}/>
                    </div>
                    <div className="userlist-lq-cell-bd">
                        <div className="userlist-lq-cell-bd-hd">
                            {item.name}
                        </div>
                        <div className="userlist-lq-cell-bd-bd">
                            {item.tel}
                        </div>
                    </div>
                    <div className="userlist-lq-cell-ft">
                        <Icon type="right" />
                    </div>
                </div>
            )
        })
        if(view.length<=0)
        {
            return <div className="userlist-lq-content">暂无用户</div>
        }
        return view;
    }
    onCloseNewsView=(e)=>{
        this.setState({
            showLogsDarwer:false,
            darwerWork:0,
            darwerUser:0
        })
    }
    logsDrawerView=(e)=>{
        const view=<Drawer
            title="用户操作日志"
            size={"small"}
            visible={this.state.showLogsDarwer}
            onClose={this.onCloseNewsView}>
            <WorkUserLogs work={this.state.darwerWork} user={this.state.darwerUser}/>
        </Drawer>
        return view;
    }
    render() {
        const JgListView=this.jglistView.bind();
        const LqListView=this.lqlistView.bind();
        const LogsDrawerView=this.logsDrawerView.bind();
        return(
            <div>
                <Tabs
                    activeId={this.state.activeId}
                    onChange={this.onTabsChange}>
                    {[
                        <TabPanel key="0" tab="已录取" id="0">
                            <div>
                                <LqListView/>
                            </div>
                        </TabPanel>,
                        <TabPanel key="1" tab="已解雇/退订" id="1">
                            <div>
                                <JgListView/>
                            </div>
                        </TabPanel>
                    ]}
                </Tabs>
                <LogsDrawerView/>
            </div>
        )
    }
}
export class WorkUserLogs extends React.Component{
    constructor(props,context) {
        super(props, context);
        this.state={
            loading:false,
            dataList:[]
        }
    }
    componentWillMount(){
        const work=this.props.work||0;
        const user=this.props.user||0;
        this.dataBind(work,user);
    }
    dataBind(work,user){
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"work",
            action:"logs",
            data:{
                work,
                user
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        loading:false,
                        dataList:result.list
                    })
                }
                else
                {
                    _this.setState({
                        loading:false,
                    })
                }
            },
            fail(){
                _this.setState({
                    loading:false,
                })
            }
        })
    }
    timeView=(e)=>{
        const view=this.state.dataList.map((item,index)=>{
            return <Timeline.Item label={
                <div className="workuserlogs-cell">
                    <div className="workuserlogs-cell-hd">{item.time}</div>
                    <div className="workuserlogs-cell-bd">{item.text}</div>
                </div>
            } key={'userlogstimeline'+index}/>
        })
        if(view.length<=0)
        {
            return <div className="userlist-lq-content">暂无操作日志信息</div>
        }
        return(
            <Timeline type="vertical">
                {view}
            </Timeline>
        );
    }
    render() {
        const TimeView=this.timeView.bind();
        return(
            <div>
                <TimeView/>
            </div>
        )
    }
}