import React from 'react';
import { Button,Input,Notify } from 'zent';
import '../style/css/login.css'
import Service from "../config/Service";
import Parameter from '../config/Config';
export default class Login extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading:false,
            usernameValue:'',
            userpwdValue:''
        }
    }
    componentWillMount() {

    }
    onUserNameChange=(e)=>{this.setState({usernameValue:e.target.value})}
    onUserPwdChange=(e)=>{this.setState({userpwdValue:e.target.value})}
    onSubmitClick=()=>{
        const {usernameValue,userpwdValue}=this.state;
        if(usernameValue.length<6||usernameValue.length>16)
        {
            Notify.warn('请输入正确的用户名')
            return false;
        }
        if(userpwdValue.length<6||userpwdValue.length>16)
        {
            Notify.warn('请输入正确的密码')
            return false;
        }
        this.setState({
            loading:true
        })
        const _this=this;
        Service.Post({
            name:"Login",
            action:"Login",
            data:{
                username:usernameValue,
                userpwd:userpwdValue
            },
            success(result)
            {
                if(result.retcode===200)
                {
                    const rtdata=result.data;
                    const sessionData={
                        id:rtdata.id,
                        name:rtdata.name,
                        token:rtdata.token,
                        role:rtdata.role
                    };
                    sessionStorage.setItem(Parameter.usersession,JSON.stringify(sessionData));
                    if(rtdata.url)
                    {
                        _this.props.history.replace(rtdata.url);
                    }
                    else
                    {
                        _this.props.history.replace("/main/index");
                    }
                }
                else
                {
                    Notify.warn(result.retmsg);
                    _this.setState({loading:false})
                }
            },
            fail(er){
                Notify.error("网络连接失败");
                _this.setState({loading:false})
            }
        })
    }
    render() {
        const {loading,usernameValue,userpwdValue}=this.state;
        return (
            <div className="login-page">
                <div className="login-header">

                </div>
                <div className="login-body">
                    <div className="login-body-box">
                        <div className="login-body-box-hd">登录</div>
                        <div className="login-body-box-bd">
                            <div className="login-body-box-bd-cells">
                                <div className="login-body-box-bd-cell">
                                    <div className="login-body-box-bd-cell-hd">
                                        账号
                                    </div>
                                    <div className="login-body-box-bd-cell-bd">
                                        <Input type="text" value={usernameValue} onChange={this.onUserNameChange} placeholder="请输入账号" maxLength="16"/>
                                    </div>
                                </div>
                                <div className="login-body-box-bd-cell">
                                    <div className="login-body-box-bd-cell-hd">
                                        密码
                                    </div>
                                    <div className="login-body-box-bd-cell-bd">
                                        <Input type="password" value={userpwdValue} onChange={this.onUserPwdChange} placeholder="请输入密码" maxLength="16"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-body-box-ft">
                            <Button
                                type="primary"
                                size="large"
                                loading={loading}
                                onClick={this.onSubmitClick}
                            >登录</Button>
                        </div>

                    </div>
                </div>
                <div className="login-footer"></div>
            </div>

        )
    }

}
