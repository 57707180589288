import React from 'react';
import Header from '../../../../component/header'
import {Button, Grid, Notify, Tag,Tabs,Sweetalert,Input,previewImage} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/user/company/index.css'
const TabPanel = Tabs.TabPanel;
export default class MainUserCompanyIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            activeId:"10",
            gridPageCurrent:0,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            shenheErrContentValue:'',
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100003,100011);
        this.dataBind(this.state.activeId,this.state.gridPageCurrent);
    }
    dataBind(status,pageindex){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"company",
            action:"list",
            data:{
                status,
                pageindex,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    onTabsChange=(e)=>{
        this.setState({
            activeId:e
        })
        this.dataBind(e,this.state.gridPageCurrent);
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(this.state.activeId,current);
    }
    onItemShenheSuccessClick=(id)=>{

        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
              title: '请确认操作',
              onConfirm: () => new Promise((resolve) => {
                    _this.onStateSubmit({
                        id,
                        status:20,
                        content:'',
                        resolve
                    })
              }),
              parentComponent: this
            });
    }
    onItemShenheErrorClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:<div><Input maxLength="140" onChange={this.onShenheErrValueChange} placeholder="请输入拒绝理由" /></div>,
              title: '请输入拒绝理由，提交成功后无法恢复，请确认',
              onConfirm: () => new Promise((resolve,reject) => {
                  const content=_this.state.shenheErrContentValue;
                  if(content.length<=0)
                  {
                      Notify.warn("请输入拒绝理由");
                      reject();
                  }
                  else
                  {
                      _this.onStateSubmit({
                          id,
                          status:30,
                          content,
                          resolve
                      })
                      _this.setState({
                          shenheErrContentValue:''
                      })
                  }
              }),
              parentComponent: this
            });
    }
    onShenheErrValueChange=(e)=>{
        const value=e.target.value;
        this.setState({
            shenheErrContentValue:value
        })
    }
    onStateSubmit=(data)=>{
        const _this=this;
        const id=data.id;
        const status=data.status;
        const content=data.content;
        Service.Post({
                        name:"company",
                        action:"status",
                        data:{
                            id,
                            status,
                            content:content
                        },
                        success(result){
                            if(result.retcode==200)
                            {
                                Notify.success("操作成功");
                                _this.dataBind(_this.state.activeId,_this.state.gridPageCurrent);
                            }
                            else
                            {
                                Notify.warn(result.retmsg);
                            }
                           data.resolve();
                        },
                        fail(){
                            data.resolve();
                            Notify.error("网络连接失败");
                        }
                    })
    }
    onShowMaxImage=(image)=>{
        previewImage({
          images: [image],
          index: 0,
          parentComponent: this,
          scaleRatio: 3
        });
    }
    getTabsPanels=()=>{
        const tabsPanels = [
              <TabPanel key="0" tab="待审核" id="10">
              </TabPanel>,
              <TabPanel key="1" tab="已审核通过" id="20">
              </TabPanel>,
              <TabPanel key="2" tab="审核未通过" id="30">
              </TabPanel>
            ];
        return tabsPanels;
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'LOGO',
                width:'70px',
                bodyRender:(data)=>{
                    return <img className="company-grid-avatar" onClick={this.onShowMaxImage.bind(this,data.logo)} src={data.logo}/>
                }
            },
            {
                title:'公司名称',
                width: '350px',
                bodyRender:(data)=>{
                    const typeTag=()=>{
                        if(data.type==1)
                        {
                            return <Tag style={{color: '#fff',borderColor: '#15BAD4',backgroundColor: '#15BAD4'}}>
                                  公司
                                </Tag>
                        }
                        else
                        {
                            return <Tag style={{borderColor: '#9370db', backgroundColor: '#9370db',color: '#fff'}}>
                                      个体
                                    </Tag>
                        }
                    }
                    return <div>
                        <div>{data.name}</div>
                        <div>{typeTag()}</div>
                    </div>
                }
            },
            {
                title:'营业执照',
                width:'130px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return <img className="company-grid-img" onClick={this.onShowMaxImage.bind(this,data.yyzz)} src={data.yyzz}/>
                }
            },
            {
                title:'统一信用代码',
                width:'200px',
                name:'idnumber',
            },
            {
                title:'注册日期',
                width:'100px',
                name:'createtime',
            },
            {
               title: '所在城市',
                width:'200px',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <div>{data.province}-{data.city}</div>
                    </div>
                }
            },
            {
               title: '注册地址',
                name:'address'
            },
            {
                title:'所属商家',
                width:'120px',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <div>{data.usernickname}</div>
                        <div>{data.usertel}</div>
                    </div>
                }
            },
            {
                title:'操作',
                width:'220px',
                textAlign:'center',
                bodyRender:(data)=>{
                    if(data.state==10)
                    {
                        return <div className="font12">
                       <Button type="primary" onClick={this.onItemShenheSuccessClick.bind(this,data.id)}>审核通过</Button><Button type="warning" onClick={this.onItemShenheErrorClick.bind(this,data.id)}>审核拒绝</Button>
                    </div>
                    }
                    else if(data.state==30)
                    {
                        return <div>未通过（{data.statecontent}）</div>
                    }
                    else
                    {
                        return <div>不可操作</div>
                    }
                }
            },
        ];
        return listColumns;
    }
    render() {
        return(
            <div className="work-page">
                <div className="work-header">
                     <Header data={[{name:'用户'},{name:'企业管理'}]}/>
                </div>
                <div className="work-body">
                    <div className="work-body-hd"></div>
                    <div className="work-body-bd">
                        <div className="work-body-bd-hd">
                             <Tabs
                          activeId={this.state.activeId}
                          onChange={this.onTabsChange}
                          type="card"
                        >
                            {this.getTabsPanels()}
                        </Tabs>
                        </div>
                        <Grid
                             columns={this.getGridColumns()}
                             datasets={this.state.dataList}
                             pageInfo={{
                                  current: this.state.gridPageCurrent,
                                  pageSize: 20,
                                  total: this.state.gridPageTotal,
                                  pageSizeOptions: [20],
                             }}
                             loading={this.state.gridLoading}
                             onChange={this.onGridChange.bind(this)}
                             ellipsis
                             rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
