import React from 'react';
import Header from '../../../../component/header'
import {
    Form,
    FormStrategy,
    Notify,
    FormInputField,
    Button,
    Tree,
    FieldUtils,
    BlockLoading,
    FieldSet
} from 'zent';
import Service from "../../../../config/Service";
function TreeLimit(e){
    const treeData=e.data;
    const value=e.value||[];
    const checkedKeys = Form.useField('role', value);
    const onCheckedChange = FieldUtils.useChangeHandler(
        checkedKeys,
        Form.ValidateOption.Default
    );
    return(
        <div>
            <Tree
                {...FieldUtils.useCompositionHandler(checkedKeys)}
                checkable
                size="small"
                data={treeData}
                onCheck={onCheckedChange}
                checkedKeys={checkedKeys.value}
            />
        </div>
    )
}
export default class MainSystemRoleEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            roleId:0,
            editData:{},
            treeList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100001,100007);
        const id=this.props.match.params.id||0;
        this.setState({
            roleId:id
        })
        this.treeBind();
        this.dataBind(id);
    }
    treeBind(){
        const _this=this;
        Service.Post({
            name:"menu",
            action:"treelist",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        treeList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminrole",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }

    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const treeList=this.state.treeList;
        const stateLoading=this.state.loading;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                Service.Post({
                    name:"adminrole",
                    action:"edit",
                    data:{
                        id:_this.state.roleId,
                        name:value.name,
                        note:value.note,
                        content:JSON.stringify(value.other.role)
                    },
                    success(result){
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功",1500,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const sectionValidator=(values)=>{
            if (values.role.length <= 0) {
                return {
                    name: 'error',
                    message: '至少选择一个权限',
                };
            }
            return null;
        }
        const ButtonView=()=>{
            if(stateLoading){
                return false;
            }
            return(
                <div className="form-submit app-fixed-container">
                    <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                    <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                </div>
            )
        }
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}
            >
                <FormInputField
                    name="name"
                    label="角色名称："
                    required
                    props={{
                        maxLength:25,
                        placeholder:"请输入角色名称",
                        width:'249px'
                    }}
                    initialValue={editData.name}
                />
                <FormInputField
                    name="note"
                    label="角色描述："
                    required
                    props={{
                        width:'249px',
                        maxLength:50,
                        placeholder:"最多50个汉字",
                        type:"textarea",
                        autoSize:true
                    }}
                    initialValue={editData.note}
                />
                <div className="form-cell">
                    <div className="form-cell-hd">
                        权限：
                    </div>
                    <div className="form-cell-bd">
                        <FieldSet name="other" validators={[sectionValidator]}>
                            <TreeLimit data={treeList} value={editData.content}/>
                        </FieldSet>
                    </div>
                </div>
                <ButtonView/>
            </Form>
        )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'角色管理',href:'/main/system/role'},{name:'编辑角色'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
