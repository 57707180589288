import React from 'react';
import {Button, Sweetalert, Pop, Tooltip, Icon, Dialog, Form,FormStrategy,
    Notify,
    Validators,
    FormInputField,} from 'zent'
import {Link} from 'react-router-dom'
import Parameter from "../config/Config";
import Service from "../config/Service";
function NavPopView(e){
    const list=e.list;
    const current=e.current;
    let view;
    if(list&&list.length>0)
    {
        const ItemView=list.map((item,index)=>{
            return(
                <div className="left-nav-pop-cell" key={'navpop'+current+'item'+index}>
                    <Link to={item.url}>
                    {item.text}
                </Link>
                </div>
            )
        })
        view= <Pop trigger="hover" className="left-nav-pop" position="right-center" cushion={20} centerArrow content={
            <div className="left-nav-pop-cells">
                {ItemView}
            </div>
        }>
            {e.children}
        </Pop>
    }
    else
    {
        view=e.children;
    }
    return view;
}
function Left(e) {
    let activeIndex=0;
    const menuList=e.menu||[];
    const navId=e.navid||0;
    const menuId=e.menuid||0;
    const usermess=JSON.parse(sessionStorage.getItem(Parameter.usersession));
    const [showDialog,setShowDialog]=React.useState(false);
    const form = Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const onUpPwdSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getValue();
            // onSubmit可以返回一个Promise，onSubmitSuccess会在其resolve之后调用
            return new Promise(resolve => {
               Service.Post({
                   name:"AdminUser",
                   action:"setpwd",
                   data:{
                       opwd:value.opwd,
                       npwd:value.npwd
                   },
                   success(result){
                       if(result.retcode==200)
                       {
                           Notify.success("操作成功",1500,()=>{
                               setLoading(false);
                               onCloseDialog();
                           })
                       }
                       else
                       {
                           Notify.warn(result.retmsg);
                           setLoading(false);
                       }
                   },
                   fail(){
                       Notify.error("网络连接失败");
                       setLoading(false);
                   }
               })
            });
      }, []);
    if(menuList.length<=0)
    {
        return false;
    }

    const NavView=()=>{
        const view=menuList.map((item,index)=>{
            const cellActiveClass=item.id==navId?'left-nav-body-cell active':'left-nav-body-cell';
            const icon=item.id==navId?item.icon:item.icon+'-o'
            return (
                <div className={cellActiveClass} key={'nav'+index}>
                    <NavPopView list={item.id==navId?[]:item.children} current={index}>
                        <Link to={{pathname:item.url}} className="left-nav-body-cell-box">
                            <div className="left-nav-body-cell-hd">
                                <Icon type={icon} />
                            </div>
                            <div className="left-nav-body-cell-bd">
                                {item.text}
                            </div>
                        </Link>
                    </NavPopView>
                </div>
            )
        })
        return view;
    }
    const MenuView=()=>{
        let list=[];
        menuList.map((item,index)=>{
            if(item.id==navId)
            {
                list=item.children||[];
                activeIndex=index;
            }
        });
        if(list&&list.length>0)
        {
            const ListView=list.map((item,index)=>{
                const cellActiveClass=item.id==menuId?'left-menu-body-cell active':'left-menu-body-cell';
                return(
                    <Link className={cellActiveClass} to={item.url} key={'menu'+activeIndex+''+index}>
                        {item.text}
                    </Link>
                )
            })
            return (
                <div className="left-menu">
                <div className="left-menu-header">
                    {menuList[activeIndex].text}
                </div>
                <div className="left-menu-body">
                    <div className="left-menu-body-cells">
                        {ListView}
                    </div>
                </div>
            </div>
            );
        }
        else
        {
            return <></>
        }
    }
    const onExitLogin=()=>{
        Sweetalert.confirm({
            content: <p>确定要退出登录吗？</p>,
            onConfirm: ()=>{
                sessionStorage.clear();
                window.location.replace("/login")
            },
            parentComponent: this
        });
    }
    const onCloseDialog=()=>{
        form.resetValue();
        form.model.clearError();
        setShowDialog(false);
    }
    const onUpPwd=()=>{
        setShowDialog(true);
    }
    const equalsPassword=(value, ctx)=>{
      if (value !== ctx.getSectionValue('npwd').npwd) {
        return {
          name: 'passwordEqual',
          message: '两次填写的密码不一致',
        };
      }
      return null;
    }

    return (
        <div className="page-left">
            <div className="left-nav">
                <div className="left-nav-header">
                    {usermess.name}
                </div>

                <div className="left-nav-body">
                    <div className="left-nav-body-cells">
                        <NavView/>
                    </div>
                </div>
                <div className="left-nav-footer">
                    <div className="left-nav-footer-cells">
                        <div className="left-nav-footer-cell">
                            <Tooltip trigger="hover" title="修改密码">
                                <span onClick={onUpPwd}><Icon type="settings-o" /></span>
                            </Tooltip>
                            <Dialog
                                maskClosable={false}
                                className="zent-dialog-demo-basic-dialog"
                                visible={showDialog}
                                onClose={onCloseDialog}
                                title="修改密码"
                            >
                                <Form
                                    disabled={loading}
                                    form={form}
                                    layout="horizontal"
                                    onSubmit={onUpPwdSubmit}
                                >
                                    <FormInputField
                                        name="opwd"
                                        label="原密码："
                                        required
                                        props={{
                                            type:"password",
                                            maxLength:16,
                                            placeholder:"请输入原密码",
                                            width:'249px'
                                        }}
                                        validators={[
                                           Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '原密码错误')
                                       ]}
                                        defaultValue={''}
                                    />
                                    <FormInputField
                                        name="npwd"
                                        label="新密码："
                                        required
                                        props={{
                                            type:"password",
                                            maxLength:16,
                                            placeholder:"请输入原密码",
                                            width:'249px'
                                        }}
                                        validators={[
                                           Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '至少包含字母、数字，6-16位')
                                       ]}
                                        defaultValue={''}
                                    />
                                    <FormInputField
                                        name="qpwd"
                                        label="确认密码："
                                        required
                                        validators={[equalsPassword]}
                                        props={{
                                            type:"password",
                                            maxLength:16,
                                            placeholder:"请输入原密码",
                                            width:'249px'
                                        }}
                                        defaultValue={''}
                                    />
                                     <div style={{
                                         textAlign:'right'
                                     }}>
                                         <Button onClick={onCloseDialog}>
                                            关闭
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={loading}>
                                            修改
                                        </Button>
                                     </div>
                                </Form>
                            </Dialog>
                        </div>
                        <div className="left-nav-footer-cell">
                            <Tooltip trigger="hover" title="退出登录">
                                <span onClick={onExitLogin}><Icon type="sign-out" /></span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <MenuView/>
        </div>
    );
}

export default Left;
