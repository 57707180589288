import React from 'react';
import Header from '../../../../component/header'
import {Button, Grid, Notify, Tag} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/merchant/index.css'
export default class MainSystemRoleIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(100001,100007);
        this.dataBind(this.state.gridPageCurrent);
    }
    dataBind(pageindex){
        this.setState({
            gridLoading:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"adminrole",
            action:"list",
            data:{
                pageindex,
                pagesize:gridPageSize,
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        gridLoading:false,
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
                Notify.error("网络连接失败");
            }
        })
    }
    onAddRoleClick=()=>{
        this.props.history.push('role/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('role/edit/'+id);
    }
    onGridItemStatusClick=(id,status)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminrole",
            action:"status",
            data:{
                id,
                status
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.dataBind(_this.state.gridPageCurrent);
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current);
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'ID',
                name:'id',
                width: '90px'
            },
            {
                title:'员工角色',
                name:'name',
                width: '150px'
            },
            {
                title:'描述',
                name:'note',
            },
            {
                title:'状态',
                name:'status',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"red"}>禁用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<Button type="error" onClick={this.onGridItemStatusClick.bind(this,data.id,0)}>禁用</Button>
                    }
                    else
                    {
                        statusView=<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                    }
                    return <div className="font12">
                        <Button type="warning" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {statusView}
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {

        return(
            <div>
                <Header data={[{name:'系统'},{name:'角色管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">

                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddRoleClick} type="primary">添加角色</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
